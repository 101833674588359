import React, { Component } from 'react'
import { Link } from 'gatsby'
import { navigate } from "@reach/router"

import CartContext from '../Context/CartContext'

const SAME_ADDRESS = 'use_same_address'
const DIFF_ADDRESS = 'use_diff_address'
const PICKUP_IN_STORE = 'pickup_in_store'

export default class CheckoutOverview extends Component {
  state = {
    acceptterms: false,
    error: false,
    error2: false
  }

  _handleChange = ({ target: o }) => {
    if (o.type === "checkbox") {
      this.setState({ [o.name]: o.checked });
    } else {
      this.setState({ [o.name]: o.value });
    }
  }

  _handleSubmit = (e, initiateOrder) => {
    e.preventDefault()
    const { acceptterms } = this.state

    if (!acceptterms) {
      this.setState({ error: true })
    } else {
      this.setState({ error: false, error2: false }, () => {
        initiateOrder()
          .then((result) => {
            if (result && result.success && result.checkout) {
              navigate(result.checkout)
            } else if (result && !result.success) {
              this.setState({ error2: true, error: false })
            }
          })
      })
    }
  }

  render() {
    return (
      <CartContext.Consumer>
        {({ cart, initiateOrder }) => {
          return (
            <>
              {(!cart || cart.items.length === 0) && (
                <div className="card mb-2">
                  <div className="card-body row">
                    <p>U hebt nog geen items in uw winkelmandje geplaatst.</p>
                  </div>
                </div>
              )}

              <div className="row">

                <div className="col-md-4">
                  <div className="card mb-2 w-100">
                    <div className="card-header">Producten</div>
                    <ul className="list-group list-group-flush">
                    {cart && cart.items && cart.items.length > 0 && cart.items.map((item) => (
                      <li key={`checkout-overview-${item.id}`} className="list-group-item">
                        <span>{item.product.title}</span>
                        <span className="float-right">{parseInt(item.amount).toFixed(2)}€</span>
                      </li>
                    ))}
                    {cart && cart.delivery.active ? (
                      <li className="list-group-item">
                        <span>{cart.delivery.title}</span>
                        <span className="float-right">{parseInt(cart.delivery.cost).toFixed(2)}€</span>
                      </li>
                    ) : ''}
                    </ul>
                    {cart ? (
                      <div className="card-footer">
                        <span>Totaal</span>
                        <span className="float-right">{parseInt(cart.total).toFixed(2)}€</span>
                      </div>
                    ) : ''}
                  </div>
                </div>

                <div className="col-md-4">
                  {cart && !cart.requires.address ? (
                    <div className="card mb-2">
                      <div className="card-header">
                        <Link to={`/checkout/delivery/`} className="">Levering</Link>
                      </div>
                      <div className="card-body">
                        <p>{cart ? cart.email : ''}</p>
                      </div>
                    </div>
                  ) : cart && cart.use_same_address === DIFF_ADDRESS ? (
                    <div className="card mb-2">
                      <div className="card-header">
                        <Link to={`/checkout/delivery`} className="">Levering</Link>
                      </div>
                      <div className="card-body">
                        <p>{cart.deliveryFirstName} {cart.deliveryLastName}<br />{cart.deliveryStreet} {cart.deliveryStreetnr}<br />{cart.deliveryPostalcode} {cart.deliveryCity}</p>
                      </div>
                    </div>
                  ) : cart && cart.use_same_address === PICKUP_IN_STORE ? (
                    <div className="card mb-2">
                      <div className="card-header">
                        <Link to={`/checkout/delivery/`} className="">Levering</Link>
                      </div>
                      <div className="card-body">
                        ophaling in winkel - van woensdag t.e.m. zaterdag van 10u-14u
                      </div>
                    </div>
                  ) : (
                    <div className="card mb-2">
                      <div className="card-header">
                        <Link to={`/checkout/delivery/`} className="">Levering</Link>
                      </div>
                      <div className="card-body">
                        {(cart && cart.requiresAddress()) ? (
                          <div>
                            <p>{cart.firstname} {cart.lastname}<br />{cart.invoiceStreet} {cart.invoiceStreetnr}<br />{cart.invoicePostalcode} {cart.invoiceCity}</p>
                          </div>
                        ) : (
                          <p>{cart ? cart.email : ''}</p>
                        )}
                      </div>
                    </div>
                  )}
                </div>

                <div className="col-md-4">
                  <div className="card mb-2">
                    <div className="card-header">
                      <Link to={`/checkout/information/`} className="">Factuurgegevens</Link>
                    </div>
                    <div className="card-body">
                      <p>{cart ? cart.email : ''}</p>
                      {(cart && cart.requiresAddress()) ? (
                        <div>
                          <p>{cart.firstname} {cart.lastname}<br />{cart.invoiceStreet} {cart.invoiceStreetnr}<br />{cart.invoicePostalcode} {cart.invoiceCity}</p>
                        </div>
                      ) : ''}
                    </div>
                  </div>
                </div>

              </div>

              <form onSubmit={e => this._handleSubmit(e, initiateOrder)}>
                <div className="row">

                  {this.state.error ? (
                    <div className="col-12">
                      <div className="alert alert-danger">
                        <p><i className="fa fa-chevron-down mr-3" aria-hidden="true"></i> Gelieve de algemene voorwaarden te aanvaarden.</p>
                      </div>
                    </div>
                  ) : ''}

                  <div className="col-12">
                    <div className="card mb-2">
                      <div className="card-body">
                        <div className="form-group form-check">
                          <input type="checkbox" className="form-check-input" id="inputTAC" name="acceptterms" onChange={e => this._handleChange(e)} />
                          <label className="form-check-label" htmlFor="inputTAC">Ik ga akkoord met de <Link to={`/algemene-voorwaarden`}>Algemene voorwaarden</Link> en het <Link to={`/retourneren`}>herroepingsrecht</Link>.</label>
                        </div>
                      </div>
                    </div>
                  </div>

                  {this.state.error2 ? (
                    <div className="col-12">
                      <div className="alert alert-danger">
                        <p><i className="fas fa-exclamation-circle mr-3" aria-hidden="true"></i> Er deed zich een technisch probleem voor. Gelieve ons te contacteren.</p>
                      </div>
                    </div>
                  ) : ''}

                  <div className="col-md-4 offset-md-8">
                    <div className="form-group">
                      <button className="btn btn-primary btn-block btn-lg">
                        <i className="fa fa-check mr-3" aria-hidden="true"></i> Bestellen
                      </button>
                    </div>
                  </div>

                </div>
              </form>
            </>
          )
        }}
      </CartContext.Consumer>
    );
  }
}
