import React from 'react'

import Layout from '../../components/layout'
import CheckoutOverview from '../../components/CheckoutOverview'

export default () => (
  <Layout>
    <h1 className="h2">Overzicht van je bestelling</h1>
    <CheckoutOverview />
  </Layout>
)
